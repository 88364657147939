<template>
  <div class="main-box">

    <div class="header">
      <img src="../../assets/title.svg" alt="图标" class="img" />
      <a-button class="a-button" @click="openJDapi">智能垃圾分类</a-button>
      <a-button type="danger" class="nav-btn" @click="quit">退出登录</a-button>
    </div>

    <div class="nav-container">
      <div v-show="$storage.get('userinfo').data.role === 0">
        <router-link to="/mobile/userManage">任务分派</router-link>
      </div>
      <div>
        <router-link to="/mobile/taskManage">任务管理</router-link>
      </div>
      <div>
        <router-link to="/mobile/messageManage">消息通告</router-link>
      </div>
      <div v-show="$storage.get('userinfo').data.role === 0">
        <router-link to="/mobile/charmingEasternTerritory">魅力东疆</router-link>
      </div>
      <div>
        <router-link to="/mobile/cleannessReport">清理报表</router-link>
      </div>
      <div>
        <router-link to="/mobile/birdSeeEasternTerritory">鸟瞰东疆</router-link>
      </div>
    </div>
    <!-- 京东api调用的地方 -->
    <a-modal
      v-model:visible="visible"
      title="智能识别垃圾"
      @ok="closeJDapi"
      @cancel="closeJDapi"
      okText="确认"
      cancelText="取消"
      width="80vw"
    >
      <a-tag color="green" style="display: block;">上传图片点击识别按钮将自动识别图片中的垃圾</a-tag>
      <input 
        type="file" 
        id="File"
        ref="fileRef" 
        style="display: none;"
        @change="imgToBase64"
      />
      <div style="margin-top: 20px;">
        <a-button type="primary" @click="chooseImage" style="margin-right: 20px;">上传待识别图片</a-button>
        <a-button @click="useJDapi" :disabled="base64Url.length === 0" :loading="isLoading">识别图片</a-button>
      </div>
      <img src="" ref="imgRef" style="width: 200px; margin-top: 20px; margin-bottom: 20px;">
      <a-table :dataSource="dataGarbage" :columns="columns" v-show="dataGarbage.length !== 0"/>
    </a-modal>
  </div>
</template>

<script>
import { createVNode, defineComponent } from "vue";
import { Modal } from "ant-design-vue";
import { message } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import OSS from "ali-oss";
const columns = [
  {
    title: '垃圾名称',
    dataIndex: 'garbage_name',
    key: 'garbage_name',
    width: '10%'
  },
  {
    title: '垃圾分类',
    dataIndex: 'cate_name',
    key: 'cate_name',
    width: '10%'
  },
  {
    title: '识别可信度',
    dataIndex: 'confidence',
    key: 'confidence',
    width: '15%'
  },
  {
    title: '备注描述',
    dataIndex: 'ps',
    key: 'ps',
    width: '65%'
  }
];
const data = [];
export default defineComponent({
  data() {
    return {
      //打开京东api的弹窗
      visible: false,
      //base64的编码
      base64Url: "",
      //识别的时候需要等待
      isLoading: false,
      columns: columns,
      dataGarbage: data,
      photoUrl: ""
    }
  },
  methods: {
    quit() {
      let that = this;
      Modal.confirm({
        title: "请确认是否退出登录？",
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode("div", { style: "color: red;" }, ""),
        okText: "确定",
        cancelText: "取消",
        onOk() {
          let api = "http://39.100.158.75:8080/user/logout";
          that
            .$axios({
              url: api,
              method: "post",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              params: {
                token: that.$storage.get("userinfo").data.token,
              },
            })
            .then((response) => {
              message.info(response.data.message);
              that.$storage.remove("userinfo");
              that.$router.push({
                path: "/login",
              });
            });
        },
        onCancel() {},
      });
    },
    //打开京东api对话框
    openJDapi() {
      this.visible = true;
    },
    //关闭京东api对话框
    closeJDapi() {
      this.visible = false;
      this.base64Url = '';
      this.$refs.imgRef.src = '';
      this.dataGarbage = [];
    },
    //选择图片
    chooseImage() {
      this.$refs.fileRef.dispatchEvent(new MouseEvent("click"));
    },
    //图片转base64格式
    beforeUpload(file) {
      const jspicture =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg";
      if (!jspicture) {
        message.error(file.name + "上传文件格式错误");
      }
      return jspicture;
    },
    imgToBase64(e) {
      this.photoUrl = '';
      this.dataGarbage = [];
      let that = this;
      let file = e.target.files[0];
      e.target.value = '';
      let obj = new Date().getTime();
      let storeAs =
        obj + "." + file.name ;
      const client = new OSS({
        region: "oss-cn-beijing",
        accessKeyId: "LTAI5tAhC8qbUaiTHPh7dEuA",
        accessKeySecret: "TfALWBoft5i0A2P11Vxd1DGqBqj9bE",
        bucket: "rubbish-demo",
      });
      if(this.beforeUpload(file)){
        client.put(storeAs, file).then((results) => {
          that.photoUrl = results.url
        })
        let imgFile = new FileReader();
        imgFile.readAsDataURL(file);
        imgFile.onload = function() {
          that.$refs.imgRef.src = this.result;
          that.base64Url = this.result.toString().replace('data:image/jpeg;base64,', '');
        }
      }
    },
    //调用京东api并返回结果
    useJDapi() {
      this.isLoading = true;
      let url = 'http://39.100.158.75:8080/other/getPhotoInfo';
      this.$axios({
        url: url,
        method: 'POST',
        headers: {
          'Content-Type':	'application/json',
          token: this.$storage.get("userinfo").data.token
        },
        data: JSON.stringify({
          photoUrl: this.photoUrl
        })
      }).then(res => {
          this.base64Url = '';
          this.isLoading = false;
          this.dataGarbage = res.data.data.result.garbage_info;
          for(const el of this.dataGarbage) {
            el.confidence = (el.confidence * 100).toFixed(2) + '%';
          }
        })
    }
  },
  mounted() {
    message.warning('本页面建议竖屏使用！', 4);
  }
});
</script>

<style lang="scss" scoped>
/* 竖屏 */
@media screen and (orientation: portrait) {
  .main-box {
    width: 100vw;
    height: 100vh;
    background: #FFF;
    .header {
      width: 100%;
      margin: 0 auto;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      display: flex;
      align-items: center;
      .nav-btn {
        margin-left: 0.2rem;
      }
      .a-button {
        background: #0E9A00;
        margin-left: 0.4rem;
        color: #FFF;
      }
      .img {
        width: 2.8519rem;
        display: block;
      }
    }
    .nav-container {
      width: 100%;
      height: 80%;
      margin: 0.5rem auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      > div {
        width: 80%;
        height: 2rem;
        line-height: 2rem;
        border: 1px solid #FFF;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18), 0 0 6px rgba(0, 0, 0, 0.13);
        font-size: 0.6rem;
        text-align: center;
        > a {
          color: #0e2231;
        }
      }
    }
  }
}
/* 横屏 */
@media screen and (orientation: landscape) {
  .main-box {
    width: 100vw;
    height: 100vh;
    background: #FFF;
    .header {
      width: 100%;
      margin: 0 auto;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      display: flex;
      align-items: center;
      .nav-btn {
        margin-left: 0.2rem;
      }
      .a-button {
        background: #0E9A00;
        margin-left: 0.4rem;
        color: #FFF;
      }
      .img {
        width: 2.8519rem;
        display: block;
      }
    }
    .nav-container {
      width: 100%;
      height: 80%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      > div {
        width: 30%;
        height: 1.2rem;
        line-height: 1.2rem;
        border: 1px solid #FFF;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18), 0 0 6px rgba(0, 0, 0, 0.13);
        font-size: 0.4rem;
        text-align: center;
        > a {
          color: #0e2231;
        }
      }
    }
  }
}
</style>
